import React from 'react';
import { Link } from 'gatsby';
import { useTheme } from 'styled-components';

import { isNotSSR } from '@utils';

import { CustomButton } from '../../components/custom-button';
import { useMatchMedia } from '../../hooks/use-match-media';
import { InfoSection } from '../EmployeeCycling/InfoSection';
import { ReadyToStartSaving } from '../EmployeeFaq/ReadyToStartSaving';

export const EmployerFaqInfoSection = () => {
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);

	return (
		<>
			<InfoSection
				title="Employee FAQs"
				text="Got more questions? We’ve got you covered."
				buttons={
					<Link to="/employee-faq/">
						<CustomButton
							hoverBackground={theme?.colors.strongWhite}
							hoverColor={theme?.colors.darkText}
						>
							For Employees
						</CustomButton>
					</Link>
				}
			/>
			<ReadyToStartSaving
				title={`Let’s get ${isDesktop ? '<br />' : ''} started`}
				text={`<p>Ready to begin?!</p>
                                               <p>Get started with Gogeta Bike to get your employees earning that extra slice.</p>`}
			>
				<Link to="/employer-cycling/plans/">
					<CustomButton color={theme.colors.darkText} background={theme.colors.strongWhite}>
						Apply for Gogeta Bike
					</CustomButton>
				</Link>
			</ReadyToStartSaving>
		</>
	);
};
